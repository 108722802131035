import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

//
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";

const MCQSCol = ({ col, number, text, image, isRightOption, subject_id }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);

  const active = isRightOption && {
    backgroundColor: "lightgreen",
    border: "1px solid green",
    borderRadius: "1px",
    color: "#fff",
    padding: "0 2px",
  };
  return (
    <p
      className={col}
      style={{
        display: "flex",
        alignItems: isMath ? "center" : "flex-start",
        margin: 0,
      }}
    >
      <span style={{ marginRight: "5px", fontWeight: "100", ...active }}>
        {number}
      </span>
      <span>
        <span>{text}</span>

        {!!image && image != "" && (
          <span>
            <img
              src={`${IMAGE_URL}/mcqs/${image}`}
              alt="image"
              style={{ maxWidth: "100%" }}
            />
          </span>
        )}
      </span>
    </p>
  );
};

export const MCQSFormat = ({ data, showMcqAnswer }) => {
  const isMath = ["5", "12"].includes(`${data?.paper_details[0]?.subject_id}`);

  return (
    <div className="body">
      {data?.paperMcqs?.map((ele, index) => (
        <div key={ele.id} className="row clearfix mb-3">
          <MCQSCol
            col="col-12"
            number={`(${convertToRoman(index + 1)})`}
            text={
              isMath ? (
                <MathJax math={ele.description} />
              ) : (
                parse(`${ele.description}`.replace(/<p>\s*(<br\s*\/?>)?\s*<\/p>/gi, ""))
              )
            }
            image={ele.file}
            subject_id={data?.paper_details[0]?.subject_id}
          />
          <MCQSCol
            col="col-3"
            number={"A."}
            text={isMath ? <MathJax math={ele.opt_a} /> : parse(`${ele.opt_a}`.replace(/<p>\s*(<br\s*\/?>)?\s*<\/p>/gi, ""))}
            image={ele.opt_a_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_a"}
          />
          <MCQSCol
            col="col-3"
            number={"B."}
            text={isMath ? <MathJax math={ele.opt_b} /> : parse(`${ele.opt_b}`.replace(/<p>\s*(<br\s*\/?>)?\s*<\/p>/gi, ""))}
            image={ele.opt_b_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_b"}
          />
          <MCQSCol
            col="col-3"
            number={"C."}
            text={isMath ? <MathJax math={ele.opt_c} /> : parse(`${ele.opt_c}`.replace(/<p>\s*(<br\s*\/?>)?\s*<\/p>/gi, ""))}
            image={ele.opt_c_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_c"}
          />
          <MCQSCol
            col="col-3"
            number={"D."}
            text={isMath ? <MathJax math={ele.opt_d} /> : parse(`${ele.opt_d}`.replace(/<p>\s*(<br\s*\/?>)?\s*<\/p>/gi, ""))}
            image={ele.opt_d_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_d"}
          />
        </div>
      ))}
    </div>
  );
};

export const PhysicsMCQSFormat = ({ data, showMcqAnswer, subjectCheck }) => {
  // Get options format based on subjectCheck
  const options =
    subjectCheck === 7 || subjectCheck === 13
      ? ["(A)", "(B)", "(C)", "(D)"]
      : ["A", "B", "C", "D"];

  return (
    <div
      style={{
        border: "1px solid #000",
        width: "100%",
        borderCollapse: "collapse",
        borderSpacing: 0,
      }}
    >
      {/* Header Row */}
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #000",
          backgroundColor: "#fff",
          minHeight: "50px",
        }}
      >
        {/* Question Number Header */}
        <div
          style={{
            width: "5%",
            borderRight: "1px solid #000",
            textAlign: "center",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          S#
        </div>

        {/* Question Header */}
        <div
          style={{
            width: "35%",
            borderRight: "1px solid #000",
            textAlign: "center",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Question
        </div>

        {/* Options Headers */}
        {options.map((letter) => (
          <div
            key={letter}
            style={{
              width: "12%",
              borderRight: "1px solid #000",
              textAlign: "center",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {letter}
          </div>
        ))}

        {/* Radio Headers */}
        <div
          style={{
            width: "12%",
            textAlign: "center",
            fontWeight: "bold",
            display: "flex",
            fontSize: "16px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {options.map((letter, index) => (
            <span
              key={letter}
              style={{
                borderRight: index < 3 ? "1px solid #000" : "none",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {letter}
            </span>
          ))}
        </div>
      </div>

      {/* Questions Rows */}
      {data?.paperMcqs?.map((ele, index) => (
        <div
          key={ele.id}
          style={{
            display: "flex",
            borderBottom: "1px solid #000",
            minHeight: "50px",
          }}
        >
          {/* Question Number */}
          <div
            style={{
              width: "5%",
              borderRight: "1px solid #000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
            }}
          >
            ({convertToRoman(index + 1)})
          </div>

          {/* Question Text */}
          <div
            style={{
              width: "35%",
              borderRight: "1px solid #000",
              display: "flex",
              alignItems: "center",
              padding: "8px",
            }}
          >
            <span
              style={{
                wordBreak: "break-word",
                overflow: "hidden",
              }}
            >
              {parse(`${ele.description}`.replace(/<p>\s*(<br\s*\/?>)?\s*<\/p>/gi, "").replace(/<p>/g, '<p style="margin-bottom: 0;">'))}
            </span>
          </div>

          {/* Options */}
          {[ele.opt_a, ele.opt_b, ele.opt_c, ele.opt_d].map((option, idx) => (
            <div
              key={idx}
              style={{
                width: "12%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "8px",
                wordBreak: "break-word",
                overflow: "hidden",
              }}
              >
              {parse(`${option}`.replace(/<p>\s*(<br\s*\/?>)?\s*<\/p>/gi, "").replace(/<p>/g, '<p style="margin-bottom: 0;">'))} 
            </div>
          ))}

          {/* Radio Buttons */}
          <div
            style={{
              width: "12%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "8px",
            }}
          >
            {[1, 2, 3, 4].map((num) => (
              <input
                key={num}
                type="radio"
                style={{
                  width: "16px",
                  height: "16px",
                  border: "2px solid #000",
                  borderRadius: "50%",
                  cursor: "pointer",
                  margin: "0",
                  appearance: "none",
                  backgroundColor: "#fff",
                  position: "relative",
                }}
                disabled
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const MathMCQSFormat = ({ data, showMcqAnswer }) => {
  const isMath = ["5", "12"].includes(`${data?.paper_details[0]?.subject_id}`);

  return (
    <div className="body">
      {data?.paperMcqs?.map((ele, index) => (
        <div
          style={{ borderBottom: "1px solid black" }}
          key={ele.id}
          className="row clearfix  mb-3"
        >
          <MCQSCol
            col="col-4"
            number={`${index + 1}.`}
            text={
              isMath ? (
                <MathJax math={ele.description} />
              ) : (
                parse(`${ele.description}`)
              )
            }
            image={ele.file}
            subject_id={data?.paper_details[0]?.subject_id}
          />
          <MCQSCol
            col="col-2"
            number={"O"}
            text={isMath ? <MathJax math={ele.opt_a} /> : parse(`${ele.opt_a}`)}
            image={ele.opt_a_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_a"}
          />
          <MCQSCol
            col="col-2"
            number={"O"}
            text={isMath ? <MathJax math={ele.opt_b} /> : parse(`${ele.opt_b}`)}
            image={ele.opt_b_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_b"}
          />
          <MCQSCol
            col="col-2"
            number={"O"}
            text={isMath ? <MathJax math={ele.opt_c} /> : parse(`${ele.opt_c}`)}
            image={ele.opt_c_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_c"}
          />
          <MCQSCol
            col="col-2"
            number={"O"}
            text={isMath ? <MathJax math={ele.opt_d} /> : parse(`${ele.opt_d}`)}
            image={ele.opt_d_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_d"}
          />
        </div>
      ))}
    </div>
  );
};
export const EnglishMCQSFormat = ({ data, showMcqAnswer, subjectCheck }) => {
  const mcqs = React.useMemo(() => {
    let mcqsData = [...data.paperMcqs];

    // Object to store grouped MCQs
    const groupedMcqs = {
      1: {},
      2: {},
      3: {},
      4: {},
    };

    // Group MCQs with the same description
    for (const mcq of mcqsData) {
      const mcqFormat = getMCQFormat(`${mcq.chapter_id}`);

      groupedMcqs[mcqFormat.id] = {
        id: mcqFormat?.id,
        statement: mcqFormat?.name,
        children: groupedMcqs[mcqFormat.id]?.children || [],
      };

      groupedMcqs[mcqFormat.id].children.push({
        ...mcq,
        description: mcqFormat.id == 2 ? "" : mcq.description,
      });
    }

    // Loop through each object in the data
    for (const key in groupedMcqs) {
      const obj = groupedMcqs[key];
      const start = startIndex(key, groupedMcqs);

      if (obj.children) {
        obj.children.forEach((child, index) => {
          child.number = index + start;
        });
      }
    }

    return groupedMcqs;
  }, [data]);

  return (
    <div className="body">
      {Object.values(mcqs || {})?.map(
        (ele, index) =>
          !!(ele?.children?.length > 0) && (
            <div key={index} className="row clearfix  mb-3">
              <MCQSCol col="col-12" text={parse(`${ele?.statement}`)} />

              <div className="body" style={{ margin: "0 20px" }}>
                {ele?.children?.map((mcqOpt) => (
                  <div
                    // style={{ borderBottom: "1px solid black" }}
                    key={`${index}-${mcqOpt?.id}`}
                    className={`row my-2 ${
                      subjectCheck === 6
                        ? "border-cls"
                        : subjectCheck === 7
                        ? ""
                        : "borderBottom"
                    }`}
                  >
                    <MCQSCol
                      col="col-4"
                      number={`${mcqOpt?.number}`}
                      text={parse(`${mcqOpt?.description}`)}
                      image={ele?.file}
                    />

                    <MCQSCol
                      col="col-2"
                      number={"O"}
                      text={parse(`${mcqOpt.opt_a}`)}
                      image={mcqOpt.opt_a_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_a"
                      }
                    />
                    <MCQSCol
                      col="col-2"
                      number={"O"}
                      text={parse(`${mcqOpt.opt_b}`)}
                      image={mcqOpt.opt_b_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_b"
                      }
                    />
                    <MCQSCol
                      col="col-2"
                      number={"O"}
                      text={parse(`${mcqOpt.opt_c}`)}
                      image={mcqOpt.opt_c_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_c"
                      }
                    />
                    <MCQSCol
                      col="col-2"
                      number={"O"}
                      text={parse(`${mcqOpt.opt_d}`)}
                      image={mcqOpt.opt_d_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_d"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
};

const getMCQFormat = (chapter) => {
  if (["333", "428"].includes(chapter)) {
    return { id: 1, name: "Choose the correct form of the verb." };
  }
  if (["334", "429"].includes(chapter)) {
    return { id: 2, name: "Choose the correct spellings." };
  }
  if (["332", "430"].includes(chapter)) {
    return {
      id: 3,
      name: "Choose the correct meanings of the underlined words.",
    };
  }
  if (["335", "431"].includes(chapter)) {
    return {
      id: 4,
      name: "Choose the correct option according to the grammar.",
    };
  }

  return {
    id: 5,
    name: "",
  };
};

const startIndex = (id, groupedMcqs) => {
  let totalLength = 1;
  for (let i = 1; i < id; i++) {
    totalLength += groupedMcqs[i]?.children?.length || 0;
  }
  return totalLength;
};
