import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

// util
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";
import { intToRoman, numberToAlphabet } from "utils/Helper";

const alphabets = ["a", "b", "c", "d", "e", "f", "g", "h"];

export const ShortQuestions = ({
  is10,
  data,
  subject_id,
  isMcqExist,
  subjectCheck,
}) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = !isMath && isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div className="mb-3">
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>
                  {is10 && subjectCheck === 12 ? (
                    <>{index + startingQuestionNumber}.</>
                  ) : subjectCheck === 13 ? (
                    <>{index + 1}.</>
                  ) : (
                    <>
                      {subjectCheck === 12 ? (
                        <>Q{index + startingQuestionNumber}.</>
                      ) : !is10 && subjectCheck === 6 ? (
                        <></>
                      ) : (
                        <>Q-{intToRoman(index + startingQuestionNumber)}.</>
                      )}
                    </>
                  )}{" "}
                </span>
                {ele.text}
              </div>
              <div className="col-2 text-right">
                {subjectCheck === 13 ||
                  subjectCheck === 12 ||
                  subjectCheck === 6 ||
                  subjectCheck === 7
                  ? ""
                  : ele.marks}
              </div>
            </div>
            {ele.ui_style == "bullet" && (
              <>
                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <BulletShortSubQuestions
                        ques={ques}
                        text={parse(`${ques.question}`)}
                        number={
                          subjectCheck === 6
                            ? `Q.${index + 1}`
                            : `${index + 1}.`
                        }
                        isMath={isMath}
                        index={index}
                      />
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "rtl_row_2_bullet" && (
              <>
                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <BulletShortSubQuestions
                        ques={ques}
                        text={parse(`${ques.question}`)}
                        number={`(${numberToAlphabet(index + 1)})`}
                        isMath={isMath}
                        index={index}
                      />
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "bullet_roman" && (
              <>
                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <BulletShortSubQuestions
                        ques={ques}
                        text={parse(`${ques.question}`)}
                        number={`${convertToRoman(index + 1)}.`}
                        isMath={isMath}
                        index={index}
                      />
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "" && (
              <>
                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <BulletShortSubQuestions
                        ques={ques}
                        text={parse(`${ques.question}`)}
                        number={``}
                        isMath={isMath}
                        index={index}
                      />
                    </>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const LongQuestions = ({ data, startIndex, subject_id, isMcqExist }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isChemistry = ["6"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-3">
          <div className="row clearfix font-weight-bold mb-2">
            <div className="col-10">
              <span>Q.{index + startIndex + startingQuestionNumber} </span>{" "}
              {ele.text}
            </div>
            {/* <div className="col-2 text-right">{ele.marks}</div> */}
          </div>

          {ele.ui_style == "math_long_q9_include_or_and_single_marks" &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span style={{ marginRight: "5px", fontWeight: "bold" }}>{`(${alphabets[QueIndex + 0]
                    })`}</span>
                  <span style={{ width: "100%" }}>
                    <MathJax math={`${ques.question}`} />
                  </span>

                  {QueIndex == 0 && (
                    <div
                      style={{ maxWidth: "100%" }}
                      className="text-right font-weight-bold"
                    >
                      (0{ques.marks})
                    </div>
                  )}
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="image"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}

                {ele?.question_ids.length > 1 && QueIndex == 0 && (
                  <div
                    className="text-center font-weight-bold mt-1 mb-1"
                    style={{ width: "100%" }}
                  >
                    OR
                  </div>
                )}
              </>
            ))}

          {!ele.ui_style &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span style={{ marginRight: "5px", fontWeight: "bold" }}>{`(${alphabets[QueIndex + 0]
                    })`}</span>
                  <span style={{ width: "100%" }}>
                    {isMath ? (
                      <MathJax math={`${ques.question}`} />
                    ) : (
                      parse(`${ques.question}`)
                    )}
                  </span>

                  <div
                    style={{ maxWidth: "100%" }}
                    className="text-right font-weight-bold"
                  >
                    (0{ques.marks})
                  </div>
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="image"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}
              </>
            ))}
        </div>
      ))}
    </div>
  );
};

export const LongEnglishQuestions = ({
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-5">
          {
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>
                  Q-{intToRoman(index + startIndex + startingQuestionNumber)}.{" "}
                </span>{" "}
                {ele.text}
              </div>
              <div className="col-2 text-right">{ele.marks}</div>
            </div>
          }

          {ele.ui_style == "rtl_row_2_bullet" && (
            <>
              <div className="row rtl-list text-right">
                {ele?.question_ids?.map((ques, queIndex) => (
                  <BulletSubQuestions
                    col="col-6"
                    number={`${queIndex + 1}.`}
                    text={parse(`${ques.question}`)}
                  />
                ))}
              </div>
            </>
          )}

          {ele.ui_style == "single_line_bullet" && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-12"
                  number={`${numberToAlphabet(queIndex + 1)}.`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {ele.ui_style == "bullet" && (
            <div className="row mx-5">
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col={is10 ? `col-4` : `col-12`}
                  number={
                    is10
                      ? `(${convertToRoman(queIndex + 1)})`
                      : `${convertToRoman(queIndex + 1)}.`
                  }
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {ele.ui_style == "punjab_10_english_essay_writing_and_para" && (
            <Punjab10EssayWriting data={ele} />
          )}

          {ele.ui_style == "punjab_10_translation_with_ten_sentences" && (
            <Punjab10ParaTranslation data={ele} />
          )}

          {!ele.ui_style && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <div className="col-12">
                  <div style={{ display: "flex", width: "100%" }}>
                    <span style={{ minWidth: "35px", fontWeight: "bold" }}>
                      {queIndex == 0 && !ele.text
                        ? `Q${index + startIndex + startingQuestionNumber}.`
                        : ""}
                    </span>

                    <span
                      className="text-margin-none"
                      style={{ width: "100%" }}
                    >
                      {parse(`${ques.question}`)}
                    </span>

                    {queIndex == 0 && !ele.text && (
                      <div
                        style={{ maxWidth: "100%" }}
                        className="text-right font-weight-bold"
                      >
                        {ele.marks}
                      </div>
                    )}
                  </div>

                  {queIndex + 1 < ele?.question_ids?.length && (
                    <div
                      className="text-center font-weight-bold mt-1 mb-1"
                      style={{ width: "100%" }}
                    >
                      OR
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const LongPhysicsQuestions = ({
  subjectCheck,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-1">
          {
            <div className="row clearfix">
              <div className="col-1 font-weight-bold">
                {subjectCheck === 7 ? (
                  <span>
                    Q.{intToRoman(index + startIndex + startingQuestionNumber)}{" "}
                  </span>
                ) : subjectCheck === 6 ? (
                  <span>
                    Q-{intToRoman(index + startIndex + startingQuestionNumber)}{" "}
                  </span>
                ) : subjectCheck === 13 ? (
                  <span>{index + startIndex + 1}. </span>
                ) : (
                  <span>{index + startIndex + startingQuestionNumber}. </span>
                )}{" "}
                {ele.text}
              </div>
              <div className="col-9">
                {ele.ui_style == "single_line_bullet" && (
                  <div className="row" style={{ marginBottom: "0.3rem" }}>
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        number={
                          subjectCheck === 6 || subjectCheck === 7
                            ? `(${numberToAlphabet(queIndex + 1)})`
                            : `${convertToRoman(queIndex + 1)}.`
                        }
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
                {ele.ui_style == "bullet" && (
                  <div className="row" style={{ marginBottom: "0.3rem" }}>
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        number={`${numberToAlphabet(queIndex + 1)}.`}
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
              </div>
              {subjectCheck === 13 && (
                <div className="col-2 text-right">{`(${ele.marks})`}</div>
              )}
              {subjectCheck !== 13 && (
                <div className="col-2 text-right">{`${ele.marks}`}</div>
              )}
            </div>
          }
        </div>
      ))}
    </div>
  );
};

export const LongMathQuestions = ({
  isMath,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = !isMath && isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-5">
          {
            <div className="row clearfix  mb-2">
              <div className="col-1 font-weight-bold">
                <span>
                  {!is10 && "Q"}
                  {index + startIndex + startingQuestionNumber}.{" "}
                </span>{" "}
                {ele.text}
              </div>
              <div className="col-11">
                {ele.ui_style == "" && (
                  <div className="row">
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        // number={`${numberToAlphabet(queIndex + 1)}.`}
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      ))}
    </div>
  );
};

const Punjab10EssayWriting = ({ data }) => {
  const essayWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 446
  );
  const paragraphWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 446
  );
  return (
    <div style={{ display: "flex" }}>
      <div style={{ minWidth: "35px" }} />
      <div className="row">
        {essayWriting.map((ques, queIndex) => (
          <BulletSubQuestions
            col="col-4"
            number={`(${convertToRoman(queIndex + 1)})`}
            text={parse(`${ques.question}`)}
          />
        ))}

        {paragraphWriting.length > 0 && (
          <>
            <div className="col-12 text-center font-weight-bold mt-1 mb-1">
              OR
            </div>

            <div
              className="col-12 font-weight-bold mb-1"
              style={{ marginLeft: "-10px" }}
            >
              Write a paragraph of 100-150 words on any ONE of the following
              topics:
            </div>

            {paragraphWriting.map((ques, index) => (
              <BulletSubQuestions
                col="col-4"
                number={`(${convertToRoman(index + 1)})`}
                text={parse(`${ques.question}`)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const Punjab10ParaTranslation = ({ data }) => {
  const paraTranslation = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 448
  );
  const tenSentences = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 448
  );
  return (
    <>
      <div className="row rtl-list text-right">
        {paraTranslation?.map((ques, queIndex) => (
          <div className="col-12">{parse(`${ques.question}`)}</div>
        ))}
      </div>

      {tenSentences.length > 0 && (
        <div
          className="text-center font-weight-bold mt-1 mb-1"
          style={{ width: "100%" }}
        >
          OR
        </div>
      )}

      <div className="row">
        {tenSentences?.map((ques, queIndex) => (
          <div className="col-12">{parse(`${ques.question}`)}</div>
        ))}
      </div>
    </>
  );
};

const BulletSubQuestions = ({ col, number, text }) => {
  return (
    <p
      className={col}
      style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
    >
      <span style={{ minWidth: "35px", fontWeight: "500" }}>{number}</span>
      <span>{text}</span>
    </p>
  );
};

const BulletShortSubQuestions = ({ ques, isMath, index, number }) => {
  return (
    <>
      <p
        className="col-12"
        style={{
          display: "flex",
          alignItems: isMath ? "flex-start" : "flex-start",
          margin: 0,
        }}
      >
        <span style={{ minWidth: "35px" }}>{`${number}`}</span>
        <span>
          {isMath ? (
            <MathJax math={`${ques.question}`} />
          ) : (
            parse(`${ques.question}`.replace(/<p>/g, '<p style="margin-bottom: 0;">'))
          )}
        </span>
      </p>
      {ques.file && (
        <p
          className="col-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-0.5rem",
            marginBottom: "1rem",
          }}
        >
          <img
            src={`${IMAGE_URL}/${ques.file}`}
            alt="image"
            style={{ maxWidth: "350px" }}
          />
        </p>
      )}
    </>
  );
};
